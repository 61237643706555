<script>
import { SIGN_IN } from '../../store/actions'

export default {
  name: 'LocalAuthentication',
  data() {
    return {
      username: '',
      password: '',
      error: '',
      usernameRules: [
        v => !!v || this.$t('signin.username_required'),
      ],
      passwordRules: [
        v => !!v || this.$t('signin.password_required'),
      ],
    }
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`)
    this.$store.commit('isLoading', false)
  },
  methods: {
    async signIn() {
      console.log('Sign in')
      try {
        // remove white spaces from username
        this.username = this.username.trim()
        await this.$store.dispatch(SIGN_IN, { username: this.username, password: this.password })
        console.log('Sign in stopOnboarding')
        this.$router.push('/Dashboard')
      }
      catch (error) {
        console.log('Sign in error', error)
        this.error = this.$te(`error.${error?.message}`) ? this.$t(`error.${error?.message}`) : this.$t('error.default')
        // this.$router.push('/SignUp')
      }
    },
  },
}
</script>

<template>
  <v-form @submit.prevent="signIn">
    <v-text-field
      v-model="username"
      :rules="usernameRules"
      :label="$t('signin.username') "
      required
      aria-autocomplete="username"
      color="#0ea4b5"
    />
    <v-text-field
      v-model="password"
      :rules="passwordRules"
      :label="$t('signin.password')"
      required
      type="password"
      aria-autocomplete="password"
      color="#0ea4b5"
    />
    <p class="error--text">
      {{ error }}
    </p>
    <v-btn
      text
      class="advance-btn"
      type="submit"
    >
      {{ $t('signin.button') }}
    </v-btn>
  </v-form>
</template>

<style>

</style>
